import MuiButton from './MuiButton';
import MuiTextField from './MuiTextField';
import MuiPaper from './MuiPaper';
import MuiTab from './MuiTab';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTablePagination from './MuiTablePagination';
import MuiCheckbox from './MuiCheckbox';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiTableFooter from './MuiTableFooter';
import MuiTable from './MuiTable';


const overrides =  {
    MuiButton,
    MuiTextField,
    MuiPaper,
    MuiTab,
    MuiTableSortLabel,
    MuiTablePagination,
    MuiCheckbox,
    MuiFormControlLabel,
    MuiTableFooter,
    MuiTable
}

export default overrides;