import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { EmailRounded } from '@material-ui/icons';

import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import { useNotifications } from '../../../components/notification-snackbar';
import Assets from '../../../assets';
import Styles from './style';

const useStyles = makeStyles(Styles);

const RecoverPassword = () => {

    const { forgotPassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async values => {
            try {
                const response = await forgotPassword(values.email);
                if (response) {
                    triggerNotification("success", "notifications-recoverpassword-emailsent-message", "notifications-recoverpassword-emailsent-title");
                    history.push(`/auth/recover-password/confirm?user=${values.email}`);
                }
            } catch (error) {
                throw error;
            }
        },
    });

    return (
        <div className={classes.root} >
            <div className={classes.header}>
                <div className={classes.headerLeftBox}></div>
                <div className={classes.headerCenterBox}>
                    <p>{t('app-name-title')}</p>
                </div>
                <div className={classes.headerRightBox}>
                    <img src={Assets.Images.cyc_bullet_decor} alt="" />
                </div>
            </div>

            <div className={classes.area} style={{marginTop: '60px'}}>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('recoverpass-text-title')}</div>
                    <div className={classes.subtitle}>{t('recoverpass-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="email"
                            type='email'
                            placeholder={t('recoverpass-input-email')}
                            variant="outlined"
                            InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                            onChange={formik.handleChange}
                            required
                        />
                        <Button type='submit' variant="contained"  style={{width: '100%', marginTop: '20px'}} disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('recoverpass-btn-text')
                        }</Button>
                    </form>
                    
                    <span className={classes.subtext} style={{textAlign: 'center', marginTop: '40px'}}>{t('recoverpass-text-subtext-1')}&nbsp;&nbsp;
                        <a component="button" onClick={() => history.push('/auth/login')} className={classes.anchor}>{t('recoverpass-text-subtext-2')}</a>
                    </span>
                </Paper>
            </div>
        </div>
    );
}

export default RecoverPassword;













// const multiStyle = (...args) => {
//     let styles = {};
//     for (var i = 0; i < args.length; {
//         styles = { ...styles, ...args[i] };
//     }
//     console.log(args);
//     return styles;
// }

// const multiClassNames = (...args) => {
//     let classes = "";
//     for (var i = 0; i < args.length; {
//         classes += args[i] + " "
//     }
//     console.log(classes);
//     return classes;
// }
