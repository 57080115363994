const MuiPaper = {
    root: {
        padding: '35px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none !imoportant'
    },
    elevation0: {
        borderRadius: '10px',
        boxShadow: '1px 1px 10px #EEEEEE'
    },
    elevation2: {
        boxShadow: 'none'
    }
};

export default MuiPaper;