const MuiCheckbox = {
    root: {
        "& .MuiIconButton-label": {
            color: "#d0212a"
        }
    },
    label: {
        color: "pink"
    }
};

export default MuiCheckbox;
