const MuiButton = {
    root: {
        // width: '100%',
        minWidth: '150px',
        height: '48px',
        // margin: '25px 0px 25px 0px',
        borderRadius: '10px',
        padding: '10px',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        lineHeight: '18px',
        "& .MuiTouchRipple-root span": {
            backgroundColor: 'rgba(203,33,42,0.2)',
        },
    },
    contained: {
        color: '#FFFFFF',
        borderRadius: '10px',
        textTransform: 'uppercase',
        backgroundColor: "#d0212a",
        fontWeight: 'regular',
        '&:hover': {
            backgroundColor: "#94070a",
        },
        '&.Mui-disabled': {
            color: '#F0F0F0',
            backgroundColor: 'rgba(203,33,42,0.3)'
        }
    },
    containedPrimary: {
        borderRadius: '10px',
        backgroundColor: "#d0212a",
        '&:hover': {
            backgroundColor: "#94070a",
        }
    },
    containedSecondary: {
        borderRadius: '10px',
        backgroundColor: "#1E1E1E",
        '&:hover': {
            backgroundColor: "rgb(30,30,30, 0.95)",
            '& .MuiButton-label': {
                color: '#fff'
            }
        }
    },
    outlined: {
        borderRadius: '10px',
        border: '1px solid #d0212a',
        color: '#d0212a',
        backgroundColor: "#ffffff",
        fontWeight: 'regular',
        '&:hover': {
            backgroundColor: 'rgba(208, 33, 42, 0.1)',
            border: '1px solid #d0212a',
        }
    },
    endIcon: {
        marginLeft: '0px'
    }
};

export default MuiButton;
