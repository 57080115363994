import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './i18n';
// import awsmobile from './aws-exports';
// import Amplify from 'aws-amplify';

// fetch(`${process.env.PUBLIC_URL}/aws_config.json`)
//   .then(response => { return response.json(); })
//   .then(data => {
//     console.log(data);
//     Amplify.configure(data);
//   });
  // Amplify.configure(awsmobile);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
