import React from "react";
import { makeStyles } from '@material-ui/styles'
import Styles from './style';

const useStyles = makeStyles(Styles);

const Content = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
}

export default Content;