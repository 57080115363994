import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';




const palette =  {
    black,
    white,
    primary: {
        main: 'rgba(176, 25, 26, 1)',
        light: 'rgba(176, 25, 26, 0.6)',     
        dark: 'rgba(105, 15, 15, 1)',
        contrastText: '#fff',
    },
    secondary: {
        main: '#1E1E1E',
        light: colors.red[500],
        dark: '#002884',
        contrastText: '#fff',
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[700],
        light: colors.red[400]
    },

    scheme: {
        primary: {
            main: 'rgba(255, 255, 255, 1)',
        },
        secondary: {
            main: 'rgba(194, 194, 194, 1)',
        },
        accent: {
            main: 'rgba(191, 53, 51, 1)', 
            hover: 'rgba(191, 53, 51, 1)',
            disabled: 'rgba(0, 0, 0, 0.45)',
        },
    },
    font: {
        primary: 'rgba(71, 71, 71, 1)',
        secundary: 'rgba(194, 194, 194, 1)',
        link: 'rgba(191, 53, 51, 1)',
    }
}

export default palette;