import { createTheme } from '@material-ui/core';
import overrides from './overrides';
import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

const Theme = createTheme({
    overrides,
    breakpoints,
    palette,
    typography
});

export default Theme
