
const MuiTab = {
    root: {
        minWidth: '0px !important',
        padding: '0px',
        margin: '0px 0px 0px 0px',
        width: '200px',
        height: '48px',
        '&$selected': {
            backgroundColor: '#000',
            color: 'white',
            '& .MuiTab-wrapper': {
                fontWeight: 'bold',
                color: '#FFF'
            }
        },
         borderTopLeftRadius: '10px',
         borderTopRightRadius: '10px',
         border: 'solid 1px #000',
    },
    wrapper: {
        display: 'flex',
        textTransform: 'none',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        fontFamily: 'Open Sans',
        fonSize: '16px',
        lineHeight: 1.25,
        fontWeight: 'normal',
        color: '#000',
    },
    labelIcon: {
        minHeight: '0px'
    },
    indicator:{
        backgroundColor: 'pink'
    },
    textColorPrimary: {
        '&.Mui-selected': {
            color: '#FFF'
        }
    }
};

export default MuiTab;
