const MuiFormControlLabel = {
    label: {
        familyFont: 'Open Sans',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '1.43',
        color: "#d0212a"
    },
};

export default MuiFormControlLabel;
