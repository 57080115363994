const Styles = theme => ({
    root: {
        width: '100%',
        // minHeight: '100vh',
        display: 'flex',
        margin: '0px auto',
        flexDirection: 'column',
    },
    body: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        margin: '0px auto',
    }
})

export default Styles;