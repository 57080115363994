
const MuiTablePagination = {
    root: {
        color: '#000',
        '& span': {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#000'
        },
        '& button': {
                fontSize: '13px',
                lineHeight: 1.54,
                fontWeight: 'bold',
                color: '#000'
            }
    },
    selectRoot: {
         fontFamily: 'Open Sans',
         fontSize: '16px',
         lineHeight: 1.54,
    }
};

export default MuiTablePagination;
