import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'
import Styles from './style';

const useStyles = makeStyles(Styles);

const Footer = () => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.column}>
                <div className={classes.message}>{t('footer-content-text-message')}</div>
                <div className={classes.bar}></div>
                <div className={classes.row}><div className={classes.textTitle}>{t('footer-content-text-company')}</div></div>
                <div className={classes.row}><div className={classes.textBody}>{t('footer-content-text-rights')}<a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" target="_blank">{t('footer-content-text-privacy')}</a></div></div>
                <div className={classes.row}><div className={classes.textBody}><a href="https://www.cycloid.pt/" target="_blank">{t('footer-content-text-web')}</a></div></div>
            </div>
        </div>
    );
}

export default Footer;