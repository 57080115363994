import cyc_logo_full from './Cycloid_Tagline_RGB_Positive.svg';
import cyc_logo from './Cycloid_RGB_Icon.svg';
import cyc_logo_parcial from './Cycloid_RGB_Positive.svg';
import cyc_bullet_decor from './cy_bullets_decor.svg';
import cy_bullets from './cy bullets decor.svg'


const Images = { 
    cyc_logo_full: cyc_logo_full,
    cyc_logo: cyc_logo,
    cyc_logo_parcial: cyc_logo_parcial,
    cyc_bullet_decor: cyc_bullet_decor,
    cy_bullets: cy_bullets

}

export default Images;