
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Button } from '@material-ui/core';
import { BlockOutlined } from '@material-ui/icons';

import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LanguageSelector } from '../../../components';
import Assets from '../../../assets';
import Styles from './style';


const useStyles = makeStyles(Styles);

const Unauthorized = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={classes.root} >
            <div className={classes.header}>
                <div className={classes.headerLeftBox}></div>
                <div className={classes.headerCenterBox}>
                    <p>{t('app-name-title')}</p>
                </div>
                <div className={classes.headerRightBox}>
                    <img src={Assets.Images.cyc_bullet_decor} alt="" />
                </div>
            </div>
            <div className={classes.area} style={{marginTop: '60px'}}>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.paper} elevation={0}>
                        <div style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <div className={classes.title}>{t('unauthorized-text-title')}</div>
                        </div>
                        <div className={classes.subtitle}>{t('unauthorized-text-subtitle')}</div>
                        <br />
                        <Button onClick={() => history.push('/auth/login')} style={{width: '100%', marginTop: '20px'}} variant="contained" disableElevation>{t('unauthorized-btn-label')}</Button>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

export default Unauthorized;