import React, { useRef, useEffect, useContext, useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowDown, KeyboardArrowUp, SubtitlesOutlined, SkipNext, SkipPrevious } from '@material-ui/icons';
import { Button, TextField, CircularProgress, MenuItem, ClickAwayListener, Grow, Paper, Popper, MenuList, FormControlLabel, Checkbox, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import { useParams, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ServiceContext } from "../../context/service/service-context";
import Assets from "../../assets";
import Styles from './style';
import PStyles from './poper';
import moment from 'moment';
import 'moment/locale/pt';


const useStyles = makeStyles(Styles);
const useStylesPopper = makeStyles(PStyles);
const TIMER = 60000; //10000
const CAPTIONS_FORMAT = [
    {
        id: 0,
        label: 'WebVtt',
        value: 'webvtt'
    },
    {
        id: 1,
        label: 'Srt',
        value: 'srt'
    },
    {
        id: 2,
        label: 'Txt',
        value: 'txt'
    }
];
const STATUS_PRGRESS = [
    {
        id: 0,
        status: 'PROCESSING',
        value: {
            'pt': 'Processamento',
            'en': 'Processing',
        }
    },
    {
        id: 1,
        status: 'READY',
        value: {
            'pt': 'Revisão',
            'en': 'Review',
        }
    },
    {
        id: 0,
        status: 'COMPLETE',
        value: {
            'pt': 'Concluído',
            'en': 'Done',
        }
    },
    {
        id: 0,
        status: 'ERRORED',
        value: {
            'pt': 'Erro',
            'en': 'Error',
        }
    }
]

const PlayerView = ({ history }) => {

    const classes = useStyles();
    const popperClass = useStylesPopper();
    const { t, i18n } = useTranslation();
    const { videoId } = useParams();
    const videoRef = useRef();
    const trackRef = useRef();
    const downloadRef = useRef();
    const { Videos } = useContext(ServiceContext);
    const [video, setVideo] = useState();
    const [currentCaption, setCurrentCaption] = useState({ id: "0", start: 0, end: 0, caption: " ", lock: false });
    const [captionsList, setCaptionsList] = useState({ isOpen: false, isDownloading: false });
    const [autoSave, setAutoSave] = useState(false)
    const [changed, setChanged] = useState(false)
    const [isInfoEditable, setIsInfoEditable] = useState(false);
    const [isReview, setIsReview] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let timer = null
        if (changed && autoSave) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                updateCaptionsRequest.handleSubmit()
                setChanged(false);
            }, TIMER)
        }
        return () => clearTimeout(timer)
    }, [changed, autoSave]);

    useEffect(() => {
        console.log(history)
        getVideo(videoId);
        window.scrollTo({ top: 0 })
    }, []);


    useEffect(() => { }, [trackRef]);


    /**
     * Request the information about a video
     * @param {string} id - ID of the video
     */
    const getVideo = async (id) => {
        try {
            setIsLoading(true)
            let result = await Videos.getVideo(id);
            result.captions = JSON.parse(result.captions);
            loadContent(result);
            setIsLoading(false)
        } catch (error) {
            throw error
        }
    }

    /**
     * Change video status
     */
    const changeVideoStatusRequest = useFormik({
        initialValues: {},
        onSubmit: async values => {
            try {
                let params = {
                    status: video.status === "READY" ? "COMPLETE" : "READY",
                    statusText: video.status === "READY" ? "Review Completed" : "Selected for review",
                }
                let result = await Videos.changeVideoStatus(videoId, params);
                setIsReview(params?.status === "COMPLETE" ? false : true);
                setVideo({ ...video, status: result.status, statusText: result.statusText });
            } catch (error) { throw error }
        },
    });

    /**
     * Update captions
     */
    const updateCaptionsRequest = useFormik({
        initialValues: {},
        onSubmit: async values => {
            try {
                let params = { captions: video?.captions }
                await Videos.updateVideoCaptions(videoId, params);
            } catch (error) { throw error }
        },
    });

    /**
     * Update video information
     */
    const updateVideoInfoRequest = useFormik({
        initialValues: {
            name: "",
            description: ""
        },
        onSubmit: async values => {
            try {
                let params = { name: values.name }
                await Videos.updateVideoName(videoId, params);
                params = { description: values.description }
                await Videos.updateVideoDescription(videoId, params);
                onChangeInfEditable();
            } catch (error) { throw error }
        },
    });

    /**
     * Download caption formats
     */
    const getCaptionRequest = useFormik({
        initialValues: {},
        onSubmit: async values => {
            try {
                captionsListHandle(false, true);
                await Videos.getVideoCaption(videoId, video?.name, values);
                captionsListHandle(false, false);
            } catch (error) { throw error }
        },
    });

    const requestTest = (value) => {
        getCaptionRequest.setValues(value);
        getCaptionRequest.handleSubmit();
    }

    /**
    * Process the data from the request and build the page content
    * @param {*} content - Data from the result of the request
    */
    const loadContent = (content) => {
        let ranges = [];
        let captions = content.captions;
        videoRef.current.src = content?.s3VideoSignedUrl;

        // captions = generateEmptySlots(content.captions);

        for (var i = 0; i < captions.length; i++) {
            let cue = new VTTCue(captions[i].start, captions[i].end, captions[i].caption);
            cue.id = i;
            videoRef.current.textTracks[0].addCue(cue);
            ranges.push({ start: captions[i].start, end: captions[i].end });
        }
        content.ranges = ranges;

        // videoRef.current.onseeking = (event) => {
            // console.log("video: ", videoRef);
            // console.log("track: ", trackRef);
            // let track = trackRef.current.track.activeCues[0];
            // console.log(track?.text);
            // let state = JSON.parse(trackRef.current.attributes['state'].value);
            // if (track) {
            //     onChangeCaption(track, state);
            // }
        // }


        // ON CPATION TRACK
        trackRef.current.oncuechange = () => {
            let track = trackRef.current.track.activeCues[0];
            let state = JSON.parse(trackRef.current.attributes['state'].value);
            console.log(state)
            if (track) {
                onChangeCaption(track, state);
            }
        }

        updateVideoInfoRequest.setFieldValue('name', content.name);
        updateVideoInfoRequest.setFieldValue('description', content.description);
        setIsReview(content?.status === "COMPLETE" ? false : true);
        setVideo(content);
        console.log("CONTENT:", content);
    }

    const generateEmptySlots = (captions) => {
        let array = [];

        for (var i = 0; i < captions.length; i++) {

            // CHECK VIDEO START
            if (i === 0 && captions[i].start > 0) {
                let item = {
                    id: null,
                    caption: '',
                    start: 0,
                    end: captions[i].start,
                    speaker_label: 'NEW SLOT',
                    wordConfidence: [],
                    isEmpty: true
                }
                array.push(item);
            }

            // CHECK VIDEO GAP
            if (i > 0 && captions[i].start != captions[i - 1]?.end) {
                let item = {
                    id: null,
                    caption: '',
                    start: captions[i - 1]?.end,
                    end: captions[i].start,
                    speaker_label: 'NEW SLOT',
                    wordConfidence: [],
                    isEmpty: true
                }
                array.push(item);
            }

            captions[i].id = i;
            captions[i].isEmpty = false;

            // ADD CURRENT CAPTION
            array.push(captions[i]);
        }
        return array;

    }

    const onChangeCaption = (cue, state) => {
        if (!state.lock) {
            videoRef.current.pause();
            videoRef.current.currentTime = state?.start + 0.01;
        } else {
            setCurrentCaption((prevState) => ({ ...prevState, id: cue?.id, start: cue?.startTime, end: cue?.endTime, caption: cue?.text }));
        }
    }

    const onChangeCaptionValue = (event) => {
        let text = event.target.value;
        onChangeCueText(currentCaption, text);
        if (!changed) setChanged(true)
    }

    const onChangeCueText = (caption, text) => {
        // CHANGE TRACK TEXT
        trackRef.current.track.activeCues[0].text = text;
        trackRef.current.track.cues[caption.id].text = text;
        // CHANGE TEXT IN CAPTIONS
        let captionList = video.captions.map((item, index) => {
            if (index == caption.id) {
                return { ...item, caption: text }
            } else {
                return item
            }
        });
        setVideo({ ...video, captions: captionList });
        // CHANGE TEXT IN CURRENT CAPTION
        setCurrentCaption((prevState) => ({ ...prevState, caption: text }));
    }

    const handleAutoSaveChange = (event) => {
        const _checked = event.target.checked
        setAutoSave(_checked)
    }

    const changeLockStatus = () => {
        setCurrentCaption((prevState) => ({ ...prevState, lock: !prevState.lock }));
    }

    const captionsListHandle = (open, download) => {
        setCaptionsList({ isOpen: open, isDownloading: download });
    }

    const onChangeInfEditable = () => {
        setIsInfoEditable(!isInfoEditable);
    }

    const onBackNavigation = () => {
       // history.push("/videos");
        history.goBack()
    }

    const formatText = (text) => {
        if (text) {
            let status = STATUS_PRGRESS.find(item => item.status === text).value;
            return status[i18n.language];
        }
        return ""
    }

    const changeCaption = (step) => {
        let index = parseInt(currentCaption.id) + step;
        if (index >= 0 || index < video.captions.length) {
            let captionItem = video.captions[index];
            videoRef.current.currentTime = captionItem?.start + 0.01;
            setCurrentCaption((prevState) => ({ ...prevState, id: index, start: captionItem?.start, end: captionItem?.end, caption: captionItem?.caption }));
            //setCurrentCaption((prevState) => ({ ...prevState, id: captionItem?.id, start: captionItem?.start, end: captionItem?.end, caption: captionItem?.caption }));
        }
    }

    String.prototype.toHHMMSS = function () {
        var sec_num = parseInt(this, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    return (
        <div className={classes.root}>

            {/* HEADER */}
            <div className={classes.header}>
                <div className={classes.headerLeftBox} onClick={onBackNavigation}>
                    <KeyboardArrowLeft />
                    <div className={classes.headerCell}><p>{t('video-detail-header-action')}</p></div>
                </div>
                <div className={classes.headerCenterBox}>
                    <p>{`${formatText(video?.status)} - ${t('video-detail-header-title')}`}</p>
                </div>
                <div className={classes.headerRightBox}>
                    <img src={Assets.Images.cyc_bullet_decor} alt="" />
                </div>
            </div>

            {/* CONTENT */}
            <div className={classes.area}>
                <div style={{ maxWidth: "640px", display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                    <div className={classes.labels} style={{ margin: '10px 0px 4px 0px', textAlign: 'center' }}>{t('video-detail-content-info-title-label')}</div>
                    {
                        isInfoEditable ?
                            <TextField
                                id="name"
                                type='text'
                                variant="outlined"
                                onChange={updateVideoInfoRequest.handleChange}
                                defaultValue={updateVideoInfoRequest.values.name}
                                value={updateVideoInfoRequest.values.name}

                                InputProps={{
                                    className: classes.inputTitle
                                }}
                                required
                                disabled={updateVideoInfoRequest.isSubmitting ? true : false}
                            /> :
                            <div className={classes.subtitle} style={{ textAlign: 'center', color: '#d0212a' }}>{updateVideoInfoRequest.values.name}</div>
                    }
                    <div className={classes.labels} style={{ margin: '10px 0px 4px 0px', textAlign: 'center' }}>{t('video-detail-content-info-file-label')}</div>
                    <div className={classes.body} style={{ textAlign: 'center' }}>{video?.s3VideoPath.split('/')[video?.s3VideoPath.split('/').length - 1]}</div>
                    <div className={classes.labels} style={{ margin: '10px 0px 4px 0px', textAlign: 'center' }}>{t('video-detail-content-info-date-label')}</div>
                    <div className={classes.value} style={{ textAlign: 'center' }}>{moment(new Date(video?.processedDate)).format('L')}</div>
                    <div className={classes.labels} style={{ margin: '10px 0px 4px 0px', textAlign: 'center' }}>{t('video-detail-content-info-notes-label')}</div>
                    {
                        isInfoEditable ?
                            <TextField
                                id="description"
                                type='text'
                                variant="outlined"
                                onChange={updateVideoInfoRequest.handleChange}
                                defaultValue={updateVideoInfoRequest.values.description}
                                value={updateVideoInfoRequest.values.description}
                                style={{ width: '640px' }}
                                required
                                disabled={updateVideoInfoRequest.isSubmitting ? true : false}
                            /> :
                            <div className={classes.body} style={{ textAlign: 'center' }}>{updateVideoInfoRequest.values.description ? updateVideoInfoRequest.values.description : '-'}</div>
                    }
                    <div className={classes.actions} style={{ justifyContent: 'center', paddingBottom: 0 }}>
                        {
                            isInfoEditable ?
                                <form onSubmit={updateVideoInfoRequest.handleSubmit}>
                                    <Button onClick={onChangeInfEditable} variant="outlined" style={{ width: '150px', marginRight: '20px' }} disableElevation >{t('video-detail-content-info-btn-label-cancel')}</Button>
                                    <Button type='submit' variant="contained" style={{ width: '150px' }} disableElevation disabled={updateVideoInfoRequest.isSubmitting}>{
                                        updateVideoInfoRequest.isSubmitting ? <CircularProgress size={24} className={classes.progressLighter} /> : t('video-detail-content-info-btn-label-save')}</Button>
                                </form>

                                :
                                <Button onClick={onChangeInfEditable} variant="contained" style={{ width: '150px' }} disableElevation >{t('video-detail-content-info-btn-label-edit')}</Button>
                        }
                    </div>
                </div>
            </div>

            {/* DIVISION */}
            <div className={classes.division}></div>

            <div className={classes.area}>

                {/* VIDEO PALYER */}
                <div className={classes.playerContainer}>
                    <video ref={videoRef} className={classes.video} controls autoplay>
                        <track ref={trackRef} kind="captions" default state={JSON.stringify(currentCaption)}></track>
                    </video>
                    <div className={classes.actions}>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <FormControlLabel control={<Checkbox checked={currentCaption.lock} onChange={changeLockStatus} name="auto-save" />} label={t('video-detail-content-video-checkbox-label')} />
                        </div>
                        <div style={{ width: 'auto' }}>
                            <Button onClick={() => changeCaption(-1)} variant="contained" style={{ width: '48px', minWidth: '48px', marginLeft: '10px' }} disableElevation disabled={currentCaption?.id < 1 ? true : false}><SkipPrevious style={{ fontSize: '30px' }} /></Button>
                            <Button onClick={() => changeCaption(1)} variant="contained" style={{ width: '48px', minWidth: '48px', marginLeft: '10px' }} disableElevation disabled={currentCaption?.id < video?.captions.length - 1 ? false : true}><SkipNext style={{ fontSize: '30px' }} /></Button>
                        </div>
                    </div>
                </div>
                <div style={{ width: '20px' }}></div>

                {/* VIDEO CAPTIONS */}
                <div className={classes.captionsContainer}>
                    <div className={classes.captionsInfo}>
                        <div className={classes.title}>{t('video-detail-content-captions-title')}</div>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 'auto', display: 'flex', paddingRight: '12px' }}>
                                <div style={{ width: 'auto', height: '40px', marginRight: '32px' }}>
                                    <div className={classes.labels}>{t('video-detail-content-captions-index-label')}</div>
                                    <div className={classes.value}>{currentCaption?.id.toString()}</div>
                                </div>
                                <div style={{ width: 'auto', height: '40px', marginRight: '32px' }}>
                                    <div className={classes.labels}>{t('video-detail-content-captions-start-label')}</div>
                                    <div className={classes.value}>{currentCaption?.start?.toString().toHHMMSS()}</div>
                                </div>
                                <div style={{ width: 'auto', height: '40px', marginRight: '32px' }}>
                                    <div className={classes.labels}>{t('video-detail-content-captions-end-label')}</div>
                                    <div className={classes.value}>{currentCaption?.end?.toString().toHHMMSS()}</div>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ width: 'auto', height: '40px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <div className={classes.labels}>{t('video-detail-content-captions-status-label')}</div>
                                    <div className={classes.value}>{formatText(video?.status)}</div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.labels} style={{ marginBottom: '7px' }}>{t('video-detail-content-captions-caption-label')}</div>
                        {
                            isReview ?
                                <TextField
                                    id="caption"
                                    type='text'
                                    variant="outlined"
                                    InputProps={{ shrink: false }}
                                    onChange={onChangeCaptionValue}
                                    defaultValue={currentCaption?.caption}
                                    value={currentCaption?.caption}
                                    rows={6}
                                    multiline
                                    classes={{
                                        root: classes.textArea,
                                    }}
                                />
                                :
                                <div className={classes.body} style={{ height: '154px' }}>{currentCaption?.caption}</div>
                        }
                    </div>
                    <div className={classes.actions}>
                        <div style={{ width: 'auto', paddingRight: '10px', display: 'flex', flexDirection: 'center' }}>
                            {
                                isReview ?
                                    <form onSubmit={updateCaptionsRequest.handleSubmit} style={{ flex: 1 }}>
                                        <Button type='submit' variant="outlined" style={{ width: '150px', marginRight: '10px' }} disableElevation disabled={updateCaptionsRequest.isSubmitting}>{
                                            updateCaptionsRequest.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('video-detail-content-captions-btn-label-save')}</Button>
                                    </form>
                                    : null
                            }
                            <Button ref={downloadRef}
                                variant="outlined"
                                aria-controls={captionsList ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={async () => captionsListHandle(!captionsList.isOpen, false)}
                                endIcon={getCaptionRequest.isSubmitting ? null : (captionsList.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                style={{ width: '150px' }}
                                disableElevation
                                disabled={getCaptionRequest.isSubmitting}>{
                                    getCaptionRequest.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('video-detail-content-captions-btn-label-download')
                                }</Button>
                            <Popper
                                className={popperClass.pooper}
                                placement="bottom-start"
                                open={captionsList.isOpen}
                                anchorEl={downloadRef.current}
                                role={undefined}
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'center top' : 'center bottom' }} >
                                        <Paper elevation={0} className={popperClass.paper}>
                                            <div className={classes.arrowUp} />
                                            <ClickAwayListener onClickAway={() => captionsListHandle(!captionsList.isOpen, false)}>
                                                <MenuList id="menu-list-grow" autoFocusItem={captionsList} className={popperClass.menuList}>
                                                    <div className={popperClass.langHeader}>{t('popper-captions-header-title')}</div>
                                                    {CAPTIONS_FORMAT.map((caption) => (
                                                        <MenuItem
                                                            key={caption.id}
                                                            className={popperClass.menuItem}
                                                            onClick={() => requestTest(caption.value)}>
                                                            <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                                {/* <SubtitlesOutlined className={classes.icons} /> */}
                                                            </ListItemIcon>
                                                            {caption.label}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                        <div style={{ flex: 1 }}>
                            <form onSubmit={changeVideoStatusRequest.handleSubmit} style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', paddingLeft: '22px' }}>
                                <Button type='submit' variant="contained" style={{ width: isReview ? '150px' : '100%' }} disableElevation disabled={changeVideoStatusRequest.isSubmitting}>{
                                    changeVideoStatusRequest.isSubmitting ? <CircularProgress size={24} className={classes.progressLighter} /> : (isReview ? t('video-detail-content-captions-btn-label-done') : t('video-detail-content-captions-btn-label-edit'))}</Button>
                            </form>
                        </div>
                    </div>
                    {
                        isReview ? <FormControlLabel control={<Checkbox checked={autoSave} onChange={handleAutoSaveChange} name="auto-save" />} label={t('video-detail-content-captions-checkbox-label')} /> : null
                    }
                </div>
            </div>
            <div className={classes.laoding} style={{ display: isLoading ? "flex" : "none" }}>
                <CircularProgress size={60} className={classes.progress} />
            </div>
        </div>
    );
}

export default withRouter(PlayerView);