import React, { useRef, useState } from 'react';
import { MenuItem, ClickAwayListener, Grow, Paper, Popper, MenuList } from '@material-ui/core';
import { CloudDownload, SubtitlesOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Styles from './style';

const useStyles = makeStyles(Styles);

const CAPTIONS_FORMAT = [
    {
        id: 0,
        label: 'WebVtt',
        value: 'webvtt'
    },
    {
        id: 1,
        label: 'Srt',
        value: 'srt'
    },
    {
        id: 1,
        label: 'Txt',
        value: 'txt'
    },
]

const ButtonPopper = (props) => {

    const classes = useStyles();
    const downloadRef = useRef();
    const [poperList, setPoperList] = useState({ isOpen: false });

    const poperListHandle = () => {
        setPoperList({ isOpen: !poperList.isOpen });
    }

    const onClickHandler = (value) => {
        props?.onClick(value);
    }

    return (
        <>
            <div ref={downloadRef} aria-controls={poperList ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={poperListHandle}><CloudDownload className={classes.icons} style={{ cursor: 'pointer' }} /></div>
          
            
                <Popper
                    className={classes.popper}
                    placement="left"
                    open={poperList.isOpen}
                    anchorEl={downloadRef}
                    role={undefined}
                    transition
                    disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'left' ? 'left' : 'left' }} >
                            <Paper elevation={0} className={classes.paper}>
                                <ClickAwayListener onClickAway={poperListHandle}>
                                    <MenuList id="menu-list-grow" autoFocusItem={poperList}>
                                        {CAPTIONS_FORMAT.map((caption) => (
                                            <MenuItem
                                                key={caption.id}
                                                className={classes.menuItem}
                                                onClick={() => onClickHandler(caption.value)}>
                                                <SubtitlesOutlined className={classes.icons} />
                                                {caption.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
        </>
    );
}

export default ButtonPopper;