
const MuiTable = {
    root: {
        maxWidth: '1024px',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
};

export default MuiTable;
