const typography = (theme) => {
    return {
        title_h1: {
            fontSize: '40px',
            fontWeight: 'bold',
            color: theme?.font?.primary,
        },
        title_h1_reg: {
            fontSize: '40px',
            fontWeight: 'regular',
            color: theme?.font?.primary,
        },
        title_h2: {
            fontSize: '28px',
            fontWeight: 'bold',
            color: theme?.font?.primary,
        },
        subtitle: {
            fontSize: '15px',
            fontWeight: 'regular',
            color: theme?.font?.secundary,
        },
        label: {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 'regular',
            color: theme?.font?.secundary,
        },
        body: {
            fontSize: '15px',
            fontWeight: 'regular',
            color: theme?.font?.primary,
        },
        anchor: {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 'regular',
            color: theme?.font?.link,
        }
    }
}

export default typography;