import React, { useContext } from "react";
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Styles from './style';

import Header from "./sections/header";
import Content from "./sections/content";
import Footer from "./sections/footer";
import { PrivateRoutes, PublicRoutes } from "../routes";
import { AuthContext } from "../context/auth/auth-context";
import Notifications from "../components/notification-snackbar"
import LoadingLayer from "../components/loading-layer";
import Theme from '../theme/sample';

const useStyles = makeStyles(Styles);

const Layout = () => {

    const classes = useStyles();
    const { isAuthenticated, isLoading } = useContext(AuthContext);

    return (
        <ThemeProvider theme={Theme}>
            {
                !isLoading ?
                    (isAuthenticated ?
                        <div className={classes.root}>
                            <div className={classes.body}>
                                <Header />
                                <Content>
                                    <PrivateRoutes />
                                </Content>
                                <Footer />
                            </div>
                        </div>
                        :
                        (
                            <div className={classes.root}>
                                <div className={classes.body}>
                                    <Header />
                                    <Content>
                                        <PublicRoutes />
                                    </Content>
                                    <Footer />
                                </div>
                            </div>
                        ))
                    : null
            }
            <Notifications />
        </ThemeProvider>

    );
}

export default Layout;