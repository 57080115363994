import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'pt'];

const loadLanguage = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const queryParams = urlParams.get('lng');
  const storageParam = localStorage.getItem("lng");

  if (Boolean(queryParams)) {
    localStorage.setItem("lng", queryParams);
    return queryParams;
  } else if (Boolean(storageParam)) {
    return storageParam;
  } else {
    localStorage.setItem("lng", 'pt');
    return 'pt';
  }

}

i18n.use(initReactI18next).use(Backend).init({
  lng: loadLanguage(),
  fallbackLng: languages,
  debug: false,
  supportedLngs: languages,
  whitelist: languages,
  interpolation: {
    escapeValue: false,
  }
})

export default i18n;