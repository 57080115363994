import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from '@material-ui/core';
import { Check} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Styles from './style';

const useStyles = makeStyles(Styles);

const DownloadFileRow = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const startUpload = () => {
        props?.upload(props?.data, (event) => uploadProgress(event));
        setIsDownloading(true);
    }

    const cancelUpload = () => {
        props?.remove(props?.index);
    }

    const uploadProgress = (value) => {
        setProgress(value.progress);
        setIsComplete(value.completed);
    }

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={classes.cell} style={{ flex: 1, flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto' }}>
                    <span className={classes.title}>{props?.data?.name}</span>
                    <span className={classes.subtitle}>{`${(props?.data?.size / 1048576).toFixed(2)}`}<i>&nbsp;MB</i></span>
                </div>
                
            </div>
            {!isComplete && (
                 <div className={classes.progressContainer} onClick={startUpload}>
                    <div className={classes.progress} style={{ width: `${progress}%` }} ></div>
                </div>
            )}
           

            <div className={classes.buttonsBox}>
                    {
                        isComplete ?
                            <Button
                                className={classes.buttonFinishedUpload}
                                variant="outlined"
                                color="primary"
                                disableElevation
                                endIcon={<Check />}
                                disabled
                                >
                                    {t('download-file-button-finishe-upload')}
                            </Button>
                            :
                            <>
                                 <Button
                                    className={classes.buttonUpload}
                                    onClick={() => cancelUpload()}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    
                                >
                                    {t('download-file-button-cancel')}
                                </Button>
                            
                                <Button
                                    className={classes.buttonUpload}
                                    onClick={() => startUpload()}
                                    variant="contained"
                                    color="primary"
                                    disabled={isDownloading}
                                    disableElevation
                                >
                                     {t('download-file-button-start')}
                                </Button>
                            </>
                    }
                </div>
        </div>
    )
}

export default DownloadFileRow;