const Styles = theme => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center'
    },
    main: {
        maxWidth: '100vw',
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    header: {
        width: '1024px',
        height: '77px',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
    },
    headerLeftBox: {
        width: '66px',
        height: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#d0212a',
        cursor: 'pointer',
        '& p': {
            width: '34px',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '1.43',
            margin: 0,
            padding: 0,
        },
    },
    headerCenterBox: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& p': {
            height: '52px',
            fontFamily: 'Open Sans',
            fontSize: '38px',
            lineHeight: '1.21',
            color: '#000000',
            margin: 0,
            padding: 0
        }
    },
    headerRightBox: {
        width: '66px',
        height: '100%',
    },
    headerCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    area: {
        width: '1024px',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 50px',
        [theme?.breakpoints?.down('sm')]: {
            padding: '0 8px',
        }
    },
    contentHeader: {
        height: 'auto',
        padding: '20px 0px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '57px',
        alignItems: 'center',
        [theme?.breakpoints?.down('sm')]: {
            marginBottom: '20px',
        }
    },
    titleBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& h1': {
            marginLeft: '77px',
        },
        [theme?.breakpoints?.down('sm')]: {
            '& h1': {
                marginLeft: '0',
                fontSize: '32px'
            },
        }
    },
    logo: {
        width: '148px',
        height: '38px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
    },
    form: {
        width: '100%'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '320px',
        padding: '20px 50px 40px',
        borderRadius: '20px',
        backgroundColor: '#fafafa',
        [theme?.breakpoints?.down('sm')]: {
            padding: '15px 12px 40px',
        }
    },
    title: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    subtitle: {
        textAlign: 'center',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontFamily: 'Open Sans',
        fontSize: '16px',
    },
    subtext: {
        display: 'flex',
        justifyContent: 'flex-start',
        ...theme?.typography?.label,
        color: '#000000'
    },
    anchor: {
        cursor: 'pointer',
        justifyContent: 'flex-start',
        ...theme?.typography?.anchor
    },
    icons: {
        fontSize: '22px',
        marginRight: '10px',
        color: '#969696'
    },
    iconsActive: {
        fontSize: '22px',
        color: '#d0212a'
    },
    progress: {
        color: "#F0F0F0"
    },
    footer: {
        margin: '60px 0 60px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontSize: '12px',
            margin: '0px 0 6px'
        },
        [theme?.breakpoints?.down('sm')]: {
            padding: '15px 12px 40px',
        }
    },
    cycText: {
        fontFamily: 'Open Sans',
        color: '#d0212a',
        fontWeight: 'bold'
    },
    cycPrivacy: {
        fontFamily: 'Open Sans',
        color: '#000000',
        '& a': {
            color: '#000000',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            textDecoration: 'none'
        }
    },
    cycWebsite: {
        fontFamily: 'Open Sans',
        color: '#000000'
    },
    bulletBox: {
        [theme?.breakpoints?.down('sm')]: {
            display: 'none'
        }
    }
})

export default Styles;