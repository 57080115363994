import { brown } from "@material-ui/core/colors";

const Styles = theme => ({
    root: {
        position: 'relative',
        zIndex: '10',
    },
    pooper: {
        width: '260px',
        marginRight: '4px'
    },
    paper: {
        padding: '0px',
        backgroundColor: '#fafafa',
        marginTop: '29px'
    },
    menuList: {
        width: '100%',
        padding: '0px 0 12px',
        borderRadius: '6px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#fafafa'
    },
    userInfoBox: {
        height: '76px',
        margin: '0px',
        padding: '12px 16px',
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& span': {
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: 1.38,
            margin: '0 8px'
        },
        borderTop: '1px solid #f0f0f0'
    },
    menuItem: {
        height: '48px',
        margin: '0px',
        padding: '12px 16px',
        backgroundColor: '#fafafa',
        '& span': {
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: 1.38,
            textTransform: 'uppercase',
            margin: '0 8px'
        },
        '&:hover': {
            backgroundColor: '#f0f0f0'
        },
        borderTop: '1px solid #f0f0f0'
    },
    userHeader: {
        height: '57px',
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: 0.83,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fafafa'
    },
    arrowUp: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        right: '18px',
        top: '19px',
        // boxShadow: '-5px -5px 10px 0px rgb(0 0 0 / 4%)',
        transform: 'rotateZ(45deg)',
        backgroundColor: '#fafafa',
        zIndex: 100,
    },
})

export default Styles;