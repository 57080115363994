import React, {useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Styles from './style';

const useStyles = makeStyles(Styles);

const loadingLayerStatus = {
    state: { show: false },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useLoadingLayer = () => {
    const [state, set] = useState(loadingLayerStatus.state);
    if (!loadingLayerStatus.setters.includes(set)) {
        loadingLayerStatus.setters.push(set);
    }

    useEffect(() => () => {
        loadingLayerStatus.setters = loadingLayerStatus.setters.filter(setter => setter !== set)
    }, []);

    /**
     * Open layer
     */
    function show() {
        loadingLayerStatus.setState((prevState) => ({show: !prevState.show}));
    }
    return { state, show };
}

loadingLayerStatus.setState = loadingLayerStatus.setState.bind(loadingLayerStatus);

const LoadingLayer = () => {
    const classes = useStyles();
    const { state } = useLoadingLayer();

    useEffect(() => {
        console.log(state.show)
    },[state.show]) 

    return (
        <div className={classes.root} style={{display: state.show ? 'display' : 'none'}}>
            <CircularProgress className={classes.progress} />
        </div>
    );
}

export default LoadingLayer;
