import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Button, Tabs, Tab } from '@material-ui/core';
import { Delete, Edit, Visibility, Replay, Search, Forward, Close } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt';
import { makeStyles } from '@material-ui/styles';
import { ServiceContext } from "../../context/service/service-context";
import ButtonPopper from "../../components/button-popper";
import DownloadFileRow from "../../components/download-file-row";
import { useTranslation } from 'react-i18next';
import Styles from './style';
import Assets from '../../assets';
import { TablePagination, TextField, InputAdornment } from "@material-ui/core";

import MaterialTable from 'material-table';

moment.locale('pt');


const useStyles = makeStyles(Styles);

const CAPTIONS_FORMAT = [
    {
        id: 0,
        label: 'WebVtt',
        value: 'webvtt'
    },
    {
        id: 1,
        label: 'Srt',
        value: 'srt'
    },
    {
        id: 2,
        label: 'Txt',
        value: 'txt'
    }
]

const VideosView = ({ history }) => {


    const tableRef = useRef();
    const classes = useStyles();
    const uploadRef = useRef();
    const { t } = useTranslation();
    const [filesList, setFilesList] = useState([]);
    const { Videos } = useContext(ServiceContext);
    const [content, setContent] = useState({
        processing: [],
        ready: [],
        complete: [],
        errored: [],
    })
    const [tabIndex, setTabIndex] = useState(0);
    const [tabContent, setTabContent] = useState([]);

    const [textSearch, setTextSearch] = useState('')


    useEffect(() => {
        const state = history.location.state;
        if (state) {
            setTextSearch(state?.textSearch)
            setTabIndex(state?.tabIndex)
        }   
        getVideosRequest.handleSubmit();
        window.scrollTo({ top: 0 })
    }, []);

     useEffect(() => {
        tableRef.current.dataManager.changeSearchText(textSearch);
        tableRef.current.setState({ searchText: textSearch });
        tableRef.current.setState(tableRef.current.dataManager.getRenderState());
     }, [textSearch, tableRef]);
    

    const getVideosRequest = useFormik({
        initialValues: {},
        onSubmit: async values => {
            try {
                let result = await Videos.allVideos();
                let filtered = await constructData(result?.videos);
                setContent(filtered);
                tabContentHandler(filtered, tabIndex);
            } catch (error) { throw error }
        },
    });

    const deleteVideoRequest = async (id) => {
        try {
            await Videos.deleteVideo(id);
            getVideosRequest.handleSubmit();
        } catch (error) { throw error }
    };

    const processVideoRequest = async (id) => {
        try {
            await Videos.processVideo(id);
        } catch (error) { throw error }
    };

    const getCaptionsRequest = async (id, name, format) => {
        try {
            await Videos.getVideoCaption(id, name, format);
        } catch (error) { throw error }
    }

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        tabContentHandler(content, newValue);
    };

    const constructData = async (data) => {
        let array = { processing: [], ready: [], complete: [], errored: [] }
        data.map(prop => {
            array[prop.status.toLowerCase()].push(prop)
        })
        return array
    }

    const tabContentHandler = (data, index) => {
        switch (index) {
            case 0:
                setTabContent(data['processing'])
                break;
            case 1:
                setTabContent(data['ready'])
                break;
            case 2:
                setTabContent(data['complete'])
                break;
            case 3:
                setTabContent(data['errored'])
                break;
            default:
                break;
        }
    }

    const buildTabel = (content) => {

        let data = [];
        let action = [];

        if (content[0]?.status === "PROCESSING") {

             action.push(
                rowData => ({
                    icon: () => <Delete className={classes.icons} style={{ cursor: 'pointer', margin:  'auto 10px' }} />,
                    tooltip: t('video-list-table-actions-delete-tooltip'),
                    onClick: (event, rowData) => deleteVideoRequest(rowData?.id)
                }),
            );

        } else if (content[0]?.status === "READY") { // TAB REVIEW
            action.push(
                rowData => ({
                    icon: () => <Delete className={classes.icons} style={{ cursor: 'pointer', margin:  'auto 10px'  }} />,
                    tooltip: t('video-list-table-actions-delete-tooltip'),
                    onClick: (event, rowData) => deleteVideoRequest(rowData?.id)
                }),
            );

             action.push(
                rowData => ({
                    icon: () => <Edit className={classes.icons} style={{ cursor: 'pointer', margin:  'auto 10px'  }} />,
                    tooltip: t('video-list-table-actions-edit-tooltip'),
                    onClick: (event, rowData) => rowData?.name?.action()
                }),
            );
           

        } else if (content[0]?.status === "COMPLETE") {
            action.push(
                rowData => ({
                    icon: () => <Delete className={classes.icons} style={{ cursor: 'pointer', margin:  'auto 10px'  }} />,
                    tooltip: t('video-list-table-actions-delete-tooltip'),
                    onClick: (event, rowData) => deleteVideoRequest(rowData?.id)
                }),
            );
             action.push(
                rowData => ({
                    icon: () => <Visibility className={classes.icons} style={{ cursor: 'pointer', margin:  'auto 10px'  }} />,
                    tooltip: t('video-list-table-actions-view-tooltip'),
                    onClick: (event, rowData) => rowData?.name?.action()
                }),
            );

             action.push(
                rowData => ({
                    icon: () => <ButtonPopper videoId={rowData?.id} name={rowData?.name} onClick={(type) => getCaptionsRequest(rowData?.id, rowData?.name.label, type)} />,
                    tooltip: t('video-list-table-actions-download-captions-tooltip'),
                }),
            );
        } else {
              action.push(
                rowData => ({
                    icon: () => <Delete className={classes.icons} style={{ cursor: 'pointer' }} />,
                    tooltip: t('video-list-table-actions-delete-tooltip'),
                    onClick: (event, rowData) => deleteVideoRequest(rowData?.id)
                }),
            );

            action.push(
                rowData => ({
                    icon: () => <Replay className={classes.icons} style={{ cursor: 'pointer' }} />,
                    tooltip: t('video-list-table-actions-download-reprocessing-video-tooltip'),
                    onClick: (event, rowData) => processVideoRequest(rowData?.id)
                }),
            );
        }

        content.map((prop, index) => {
            let item = {
                index: index,
                id: prop?.videoId,
                processed: moment(new Date(prop?.processedDate)).format('L'),
                name: {
                    label: prop?.name,
                    action: () => {
                       
                        history.replace({
                            state: {
                                textSearch: textSearch,
                                tabIndex: tabIndex,
                                currentPage: tableRef.current.dataManager.currentPage,
                                pageSize: tableRef.current.dataManager.pageSize
                        } })

                        history.push(`/videos/${prop?.videoId}`)
                    }
                },
                description: prop?.description,
                status: prop?.statusText,
            }
            data.push(item);
        })

        return (
            <MaterialTable
                tableRef={tableRef}
                columns={[
                    {
                        title: t('video-list-table-header-title-date'), field: 'processed', defaultSort: 'desc',
                        cellStyle: {
                                width: '10%'
                            }},
                    {
                    title: t('video-list-table-header-title-name'), field: 'name', type: 'string', render: (rowData) =>
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <div  className={clsx({
                                            [classes.nameTextBox]: true,
                                            [classes.nameTextRedBox]: (tabIndex === 1 || tabIndex === 2)
                            })}
                                onClick={() => {
                                    if (tabIndex == 1 || tabIndex == 2) {
                                        rowData?.name?.action()
                                    }
                                } }
                            >
                                <span>{rowData?.name?.label}</span>
                            </div>,
                            customFilterAndSearch: (term, rowData) => rowData?.name?.label?.toLowerCase().includes(term?.toLowerCase())
                    },
                    {
                        title: t('video-list-table-header-title-notes'), field: 'description', type: 'string',
                        render: (rowData) =>
                            <div className={classes.descriptionBox}>
                                <span>{rowData?.description}</span>
                            </div>,
                        customFilterAndSearch: (term, rowData) => rowData?.description?.toLowerCase().includes(term?.toLowerCase())
                    },
                    {
                        title: t('video-list-table-header-title-status'), field: 'status', type: 'string', render: (rowData) =>
                            <div className={classes.statusBox}>
                                <span>{rowData?.status}</span>
                            </div>,
                        customFilterAndSearch: (term, rowData) => rowData?.status?.toLowerCase().includes(term?.toLowerCase())
                    }
                ]}
                data={data}
                actions={action}
                options={{
                    showTitle: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 15, 50],
                    padding: 'dense',
                    actionsColumnIndex: -1,
                    headerStyle: {
                        height: 60,
                        borderBottomColor: '#000'
                    },
                    rowStyle: {
                        fontFamily: 'Open Sans'
                    },
                }}
                style={{ width: '100%', padding: 0 }}
                 components={{
                     Toolbar: props => {
                         return (
                             <div className={classes.toolbarBoxWrapper}>
                                 <div className={classes.toolbarBox}>
                                    <div className={classes.toolbarBoxInner}>
                                        <div className={classes.searchBox}>
                                            <TextField
                                                value={textSearch}
                                                autoFocus={true}
                                                placeholder={t('video-list-table-search-placeholder')}
                                                id="search"
                                                variant="outlined"
                                                className={classes.searchTextField}
                                                autoComplete={false}
                                                InputProps={{
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <Search />
                                                        </InputAdornment>,
                                                    endAdornment:
                                                        <InputAdornment position="start">
                                                            {textSearch?.length === 0 ?
                                                                (<Forward className={classes.forwardIcon} onClick={handleSearch} style={{ cursor: 'pointer' }} />) :
                                                                (<Close className={classes.forwardIcon} style={{ cursor: 'pointer' }} onClick={() => setTextSearch('')} />)}
                                                        </InputAdornment>
                                                }}
                                                onChange={memoizedCallbackHandleChangeSearchInput}
                                            />
                                        </div>
                                        <Button
                                            className={classes.buttonRefresh}
                                            onClick={() => getVideosRequest.handleSubmit()}
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                        >
                                            {t('video-list-info-btn-refresh')}
                                        </Button>
                                    </div>
                                 </div>
                                 {(textSearch?.length > 0) && (
                                     <div className={classes.searchInfoBox}>
                                     <p>{t('video-list-table-search-info')}</p>
                                 </div>
                                 ) }
                                 
                             </div>
                              
                          )
                         
                     },
                     Pagination: props => <TablePagination
                         {...props}
                         className={classes.pagination} />,

                }}
                 localization={{
                    pagination: {
                        labelRowsSelect: t('video-list-table-pagination-row-select'),
                        labelDisplayedRows: `{from}-{to} ${t('video-list-table-pagination-of')} {count}`,
                        firstTooltip: t('video-list-table-pagination-first-tooltip'),
                        previousTooltip: t('video-list-table-pagination-previous-tooltip'),
                        nextTooltip: t('video-list-table-pagination-next-tooltip'),
                        lastTooltip: t('video-list-table-pagination-last-tooltip')
                     },
                     body: {
                        emptyDataSourceMessage: t('video-list-table-empty-data')
                     },
                      header: {
                        actions: t('video-list-table-header-title-actions')
                    },
            }}
                
            />
        )
    }

    const uploadHandler = (event) => {
        setFilesList(array => [...array, event.target.files[0]]);
    }

    const uploadFile = async (file, callback) => {
        try {
            let result = await Videos.getUploadVideoUrl(file.name);
            let signedUrl = result.signedUrl
            await Videos.uploadFile(signedUrl, file, (event) => {
                callback(event);
            })
        } catch (error) { throw error }
    }
    
    const removeFile = async (id) => {
        setFilesList(filesList.filter((item, index) => index !== id));
    }

  
    const handleSearch = (event) => {
        setTextSearch(event.target.value)
    }

    const memoizedCallbackHandleChangeSearchInput = useCallback(
        (event) => {
            setTextSearch(event.target.value)
        },
        [],
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleBox}>
                    <h1>{t('video-list-header-title')}</h1>
                </div>
                 <div className={classes.bulletBox}>
                        <img src={Assets.Images.cy_bullets} alt="bullets" />
                    </div>
            </div>
            <div className={classes.container}>
                <div className={classes.description}>
                    <p>{t('video-list-header-info')}</p>
                </div>
                {false && (
                    <div className={classes.row}>
                    <Button
                        className={classes.buttonUpload}
                        onClick={() => uploadRef.current.click()}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        {t('video-list-info-btn-upload')}
                    </Button>
                    <input ref={uploadRef} type="file" accept="video/*" onChange={uploadHandler} hidden />
                </div>
                )}
                
                 <div className={classes.column}>
                    {
                        filesList.length > 0 ?
                            filesList.map((prop, index) => {
                                return (
                                    <DownloadFileRow key={index} index={index} data={prop} upload={uploadFile} remove={removeFile} />
                                )
                            })
                            : null
                    }
                </div>
                <div className={classes.row}>
                    <div className={classes.subtitleBox}>
                        <h3>{t('video-list-section-label-video')}</h3>
                    </div>
                </div>
               
                <div className={classes.row} style={{padding: 0, width: '1024px', marginLeft: 'auto', marginRight: 'auto'}}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        textColor="primary" variant="scrollable" scrollButtons="auto">
                        <Tab label={`${t('video-list-table-tab-title-processing')} (${content.processing.length})`}{...a11yProps(0)} />
                        <Tab label={`${t('video-list-table-tab-title-review')} (${content.ready.length})`}  {...a11yProps(1)} />
                        <Tab label={`${t('video-list-table-tab-title-done')} (${content.complete.length})`} {...a11yProps(2)} />
                        <Tab label={`${t('video-list-table-tab-title-error')} (${content.errored.length})`}  {...a11yProps(3)} />
                    </Tabs>
                </div>
                <div className={classes.row}>
                    {
                        buildTabel(tabContent)
                    }
                </div>
            </div>
        </div>
    );
}

export default withRouter(VideosView);