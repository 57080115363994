const Styles = theme => ({
    toolbar: {
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        backgroundColor: '#fafafa',
        // padding: '0 128px',
        display: 'flex',
        width: '100vw',
        position: 'fixed',
        padding: '0px',
        zIndex: 5,
        justifyContent: 'center',
    },
    root: {
        // width: '100%',
        width: '1024px',
        display: 'flex',    
    },
    center: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& p":{
            margin: 0,
            padding: 0,
            fontFamily: "Open Sans",
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#000000'
        }
    },
    leftActions: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        visibility: 'hidden',
        [theme?.breakpoints?.between('0', '600')]: {
            visibility: 'visible',
        },
    },
    rightActions: {
        width: 'auto',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 'auto'
    },
      logo: {
        width: '148px',
        height: '38px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
    },
})

export default Styles;