import React, { useContext } from "react";
import { Toolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { ExitToApp } from '@material-ui/icons';
import Styles from './style';
import { withRouter } from 'react-router-dom';
import { AuthContext } from "../../../context/auth/auth-context";
import SessionPopper from "../../../components/session-popper";
import LanguageSelector from "../../../components/language-selector";
import { useTranslation } from 'react-i18next';

import Assets from '../../../assets/';


const useStyles = makeStyles(Styles);

const Header = ({ history }) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { isAuthenticated, signout } = useContext(AuthContext);

    const goToPath = (path) => {
        history.push(path);
    }

    return (


        <Toolbar classes={{
            root: classes.toolbar
        }}>
            <div className={classes.root}>
                <div className={classes.logo}>
                    <img src={Assets.Images.cyc_logo_full} alt="logo" />
                </div>
                <div className={classes.center}>
                    {isAuthenticated ? <p>{t('app-name-title')}</p> : null}
                </div>
                <div className={classes.rightActions}>
                    <LanguageSelector type="app" />
                    {isAuthenticated ? <SessionPopper /> : null}
                </div>
            </div>
        </Toolbar>
    );
}

export default withRouter(Header);


/*

        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.logo}>
                    <img src={Assets.Images.cyc_logo_full} alt="" />
                </div>
                {
                    isAuthenticated ? <div className={classes.navOption} onClick={() => goToPath('/videos')}><span>Videos</span></div> : null
                }
                <div className={classes.navOption} onClick={() => goToPath('/about')}><span>About</span></div>
                {
                    isAuthenticated ?
                        <div className={classes.logout} >
                            <SessionPopper />
                        </div>
                        : null
                }
            </div>
        </div>

        */