const MuiTextField = {
    root: {
        width: '100%',
        // margin: '7px 0px',
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            height: '50px',
            backgroundColor: '#ffffff',
            "& .Mui-disabled ": {
                color: "rgba(0, 0, 0, 0.45)"
            },
            '& fieldset': {
                borderColor: "#c8c8c8",
            },
            '&:hover fieldset': {
                borderColor: "#d0212a",
            },
            '&.Mui-focused fieldset': {
                border: "1px solid #d0212a"
            },
            '&.Mui-disabled fieldset': {
                // borderColor: "rgba(0, 0, 0, 0.10)",
                borderColor: "transparent",
            },
            "&.Mui-disabled svg": {
                color: "rgba(0, 0, 0, 0.45)"
            },
            "& svg": {
                borderColor: "rgba(176, 25, 26, 1)"
            },
        },
        "& .MuiInputLabel-root":{
            '&.Mui-disabled': {
                color: "rgba(0, 0, 0, 0.20)",
            }
        },
        "&:hover .MuiInputLabel-root":{
            color: "rgba(176, 25, 26, 1)",
        }
    },
};

export default MuiTextField;