import { CenterFocusStrong } from "@material-ui/icons";

const Styles = theme => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center'
    },
    header: {
        width: '1024px',
        height: '77px',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
    },
    headerLeftBox: {
        width: '66px',
        height: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#d0212a',
        cursor: 'pointer',
        '& p': {
            width: '34px',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '1.43',
            margin: 0,
            padding: 0,
        },
    },
    headerCenterBox: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& p': {
            height: '52px',
            fontFamily: 'Open Sans',
            fontSize: '38px',
            lineHeight: '1.21',
            color: '#000000',
            margin: 0,
            padding: 0
        }
    },
    headerRightBox: {
        width: '66px',
        height: '100%',
    },
    headerCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },



    area: {
        width: '1024px',
        display: 'flex',
        justifyContent: 'center'
    },
    actions: {
        width: '100%',
        padding: '20px 0px 7px 0px',
        display: 'flex',
    },

    playerContainer: {
        width: '512px',
        display: 'flex',
        flexDirection: 'column'
    },
    video: {
        width: '100%',
        height: '288px',
        objectFit: 'contain',
        backgroundColor: '#000000'
    },
    captionsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    captionsInfo: {
        height: '288px',
        display: 'flex',
        flexDirection: 'column',
    },



    division: {
        width: '100%',
        height: '1px',
        margin: '40px 0px',
        backgroundColor: '#f0f0f0'
    },


    title: {
        height: '33px',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#000000'
    },
    subtitle: {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#000000'
    },
    labels: {
        height: '17px',
        fontFamily: 'Open Sans',
        fontSize: '12px',
        color: '#969696'
    },
    value: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000000'
    },
    body: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        color: '#000000'
    },
    progress: {
        color: '#d0212a'
    },
    progressLighter: {
        color: '#F0F0F0',
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
    inputTitle: {
        width: '640px',
        color: "#d0212a",
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    laoding: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        backgroundColor: '#ffffff',
        zIndex: 100
    }
})

export default Styles;