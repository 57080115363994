import React, { createContext } from 'react';
import { VideoService } from './services/videos/video-service';

export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

    const Videos = VideoService();

    return (
        <ServiceContext.Provider value={{ Videos }}>
            {props.children}
        </ServiceContext.Provider>
    )
}
export default ServiceContextProvider;