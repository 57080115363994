const Styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '120px 0px 60px 0px'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    bar: {
        width: '280px',
        height: '1px',
        margin: '20px 0px',
        backgroundColor: '#c8c8c8'
    },
    row: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '6px'
    },
    textTitle: {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 'bold',
        color: "#d0212a",
    },
    textBody: {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        color: "#000000",
        '& a': {
            textDecoration: 'none',
            color: "#000000",
        },
        '& a:hover': {
            cursor: 'pointer',
            color: "#d0212a",
        }
    },
    message: {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontStyle: 'italic',
        color: "#000000",
        textAlign: 'center'
    }
})

export default Styles;