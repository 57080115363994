
const MuiTableSortLabel = {
    root: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: 1.43,
        fontWeight: 'bold',
        '& .MuiTableSortLabel-icon': {
            color: '#d0212a',
            width: '20px',
            height: '20px',
            fontSize: '20px'
        }
    },
    active: {
        color: '#d0212a',
        '& .MuiTableSortLabel-icon': {
            color: '#d0212a !important'
        }
    }
};

export default MuiTableSortLabel;
