import { useEffect, useContext } from 'react';
import { useNotifications } from '../../../../components/notification-snackbar';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { AuthContext } from '../../../auth/auth-context';

export const VideoService = () => {

    const { triggerNotification } = useNotifications();
    const { cognitoUser, configure, currentUserPoolUser } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
    }, [cognitoUser])

    const allVideos = async () => {
        try {
            let token = await currentUserPoolUser();
            var url = `${configure.aws_api_gateway_endpoint}/videos`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.get(url, axiosConfig);
            return result.data
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-allvideos-description', 'service-videos-error-request-allvideos-title');
            console.log(error);
        }
    }

    const getVideo = async (id) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/video/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.get(url, axiosConfig);
            return result?.data?.video;
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-getvideo-description', 'service-videos-error-request-getvideo-title');
            console.log(error);
        }
    }

    const deleteVideo = async (id) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/video/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.delete(url, axiosConfig);
            if (result.status === 200)
                triggerNotification("success", 'service-videos-success-request-deletevideo-description', 'service-videos-success-request-deletevideo-title');
            return result?.data?.video;
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-deletevideo-description', 'service-videos-error-request-deletevideo-title');
            console.log(error);
        }
    }

    const getVideoCaption = async (id, filename, format) => {
        const FORMATS = {
            webvtt: '.vtt',
            srt: '.srt',
            txt: '.txt'
        }
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/caption/${id}?format=${format.toLowerCase()}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            axios.get(url, axiosConfig)
                .then(function (response) {
                    var blob = new Blob([response.data], { type: "text/vtt;charset=utf-8" });
                    saveAs(blob, filename.split('.')[0] + FORMATS[format.toLowerCase()]);
                    triggerNotification("success", 'service-videos-success-request-getcaption-description', 'service-videos-success-request-getcaption-title');
                })
                .catch(function (error) {
                    console.log(error);
                    triggerNotification("error", 'service-videos-error-request-getcaption-description', 'service-videos-error-request-getcaption-title');
                });
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-getcaption-description', 'service-videos-error-request-getcaption-title');
            console.log(error);
        }
    }

    const updateVideoCaptions = async (id, data) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/caption/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.put(url, data, axiosConfig);
            if (result.status === 200)
                triggerNotification("success", 'service-videos-success-request-updatecaptions-description', 'service-videos-success-request-updatecaptions-title');
            return result.data;
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-updatecaptions-description', 'service-videos-error-request-updatecaptions-title');
            console.log(error);
        }
    }

    const updateVideoName = async (id, data) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/videoname/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.post(url, data, axiosConfig);
            if (result.status === 200)
                triggerNotification("success", "service-videos-success-request-updateinfo-description", "service-videos-success-request-updateinfo-title");
            return result.data;
        } catch (error) {
            triggerNotification("error", "service-videos-error-request-updateinfo-description", "service-videos-error-request-updateinfo-title");
            console.log(error);
        }
    }

    const updateVideoDescription = async (id, data) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/videodescription/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.post(url, data, axiosConfig);
            if (result.status === 200)
                triggerNotification("success", "service-videos-success-request-updateinfo-description", "service-videos-success-request-updateinfo-title")
            return result.data;
        } catch (error) {
            triggerNotification("error", "service-videos-error-request-updateinfo-description", "service-videos-error-request-updateinfo-title")
            console.log(error);
        }
    }

    const processVideo = async (id) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/video/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.patch(url, {}, axiosConfig);
            if (result.status === 200)
                triggerNotification("success", "service-videos-success-request-reprocessvideo-description", "service-videos-success-request-reprocessvideo-title")
            return result?.data?.video;
        } catch (error) {
            triggerNotification("error", "service-videos-error-request-reprocessvideo-description", "service-videos-error-request-reprocessvideo-title")
            console.log(error);
        }
    }

    const changeVideoStatus = async (id, data) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/videostatus/${id}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.post(url, data, axiosConfig);
            return result?.data;
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-videostatus-description', 'service-videos-error-request-videostatus-title');
            console.log(error);
        }
    }

    const getUploadVideoUrl = async (filename) => {
        try {
            let token = await currentUserPoolUser();
            let url = `${configure.aws_api_gateway_endpoint}/upload/${filename}`
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'authorization': token ? token : cognitoUser?.signInUserSession?.idToken?.jwtToken
                }
            };
            let result = await axios.get(url, axiosConfig);
            return result.data;
        } catch (error) {
            // triggerNotification("error", "Captions falied to update", "Something went wrong");
            console.log(error);
        }
    }

    const uploadFile = async (url, data, progress) => {
        try {
            var formaData = new FormData();
            formaData.append('file', data);


            let axiosConfig = {
                headers: {
                    'Content-Type': data.type
                },
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    let status = {
                        progress: percentCompleted,
                        completed: percentCompleted === 100 ? true : false
                    }
                    progress(status);
                }
            };
            let result = await axios.put(url, data, axiosConfig);

            if (result.status === 200)
                triggerNotification("success", 'service-videos-success-request-uploadvideo-description', 'service-videos-success-request-uploadvideo-title');
            return result.data;
        } catch (error) {
            triggerNotification("error", 'service-videos-error-request-uploadvideo-description', 'service-videos-error-request-uploadvideo-title');
            console.log(error);
        }
    }

    return {
        allVideos, getVideo, getVideoCaption, updateVideoCaptions, updateVideoName,
        updateVideoDescription, deleteVideo, processVideo, changeVideoStatus,
        getUploadVideoUrl, uploadFile
    }
}