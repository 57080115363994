const Styles = theme => ({
    root: {
        width: '100%', 
        // display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        position: 'absolute', 
        top: 0, 
        bottom: 0, 
        left: 0, 
        backgroundColor: '#ffffff', 
        zIndex: 100
    },
    progress: {
        fontSize: '60px',
        color: '#d0212a'
    },
})

export default Styles;