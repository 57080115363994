const Styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0px',
        position: 'relative',
        overflow: 'hidden'
    },
    row: {
        padding: '7px 0px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    cell: {
        height: '100%',
        display: 'flex',
        textAlign: 'center'
    },
    progressContainer: {
        width: '100%',
        height: '8px',
        display: 'flex',
        borderRadius: '10px',
        backgroundColor: '#F0F0F0',
        maxWidth: '320px',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '20px'
    },
    progress: {
        width: '0%',
        height: '100%',
        borderRadius: '10px',
        backgroundColor: '#000',
        transition: 'all 0.2 linear'
    },
    roundButton: {
        width: '30px',
        height: '30px',
        margin: '2px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        fontSize: '10px',
        borderRadius: '30px',
        backgroundColor: theme.palette.scheme.accent.main,
        cursor: 'pointer'
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '1.33',
        fontFamily: 'Open Sans',
        marginBottom: '10px'
    },
    subtitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '1.13',
        fontFamily: 'Open Sans',
        '& i': {
            fontWeight: 'normal',
            fontStyle: 'normal'   
        }
    },
    overlay: {
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    buttonsBox: {
        marginTop: '20px',
        marginRight: 'auto',
        marginLeft: 'auto',
        '& button': {
            margin: '0 10px'
        }
    },
    buttonFinishedUpload: {
        width: '320px',
        color: '#d0212a !important',
        border: '1px solid #d0212a !important'
    }
})

export default Styles;