const Styles = theme => ({
    root: {
        flex: 1,
    },
    header: {
        height: 'auto',
        paddingTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '60px',
        alignItems: 'baseline',
        maxWidth: '1024px',
        marginLeft: 'auto',
        marginRight:'auto',
        [theme?.breakpoints?.down('sm')]: {
             marginBottom: '20px',
        }
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        "& img": {
            width: '100%',
            marginTop: '40px'
        }
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 20px 0px',
        backgroundColor: 'brow'
    },
    titleBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& h1': {
            marginLeft: '77px',
            fontFamily: 'Open Sans',
            fontSize: '38px',
            lineHeight: 1.21,
            fontWeight: 'normal',
            marginTop: 0,
            marginBottom: 0
        },
         [theme?.breakpoints?.down('sm')]: {
           '& h1': {
                marginLeft: '0',
                fontSize: '32px'
            }
        }
    },
     bulletBox: {
          [theme?.breakpoints?.down('sm')]: {
             display: 'none'
        }
    },
    label: {
        fontSize: '1.1rem',
        color: "lightslategray"
    },
    subtitleBox: {
        margin: '40px auto',
        '& h3': {
            fontFamily: 'Open Sans',
            fontSize: '24px',
            lineHeight: 1,
            fontWeight: 'bold',
            margin: 0
        }
    },
    description: {
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '40px',
        '& p': {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            lineHeight: 1.25,
            fontWeight: 'normal',
            textAlign: 'center',
            whiteSpace: 'pre-line'
        }
    },
    progress: {
        color: 'rgb(190, 60, 49)'
    },
    icons: {
        fontSize: '25px',
        color: '#d0212a'
    },
    paper:{
        padding: '5px'
    },
    buttonUpload: {
        width: '320px',
        height: '48px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    table: {
         padding: 0
    },
    toolbarBox: {
        backgroundColor: '#000',
        height: '90px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    toolbarBoxInner: {
        width: '1024px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'row'
    },
    buttonRefresh: {
        width: '150px',
        height: '48px',
        marginLeft: '20px',
    },
    nameTextBox: {
        '& span': {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            lineHeight: 1.25,
            fontWeight: 'bold',
            textAlign: 'left',
            cursor: 'pointer',
             display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden'
        }
    },
    nameTextRedBox: {
        '& span': {
            color: '#d0212a'
        }
    },
    pagination: {
        backgroundColor: '#FFF',
        '& .MTablePaginationInner-root': {
            backgroundColor: 'red'
        }
    },
    descriptionBox: {
        '& span': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden'
        }
    },
    statusBox: {
        '& span': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden'
        }
    },
    searchBox: {
        width: '320px'
    },
    searchTextField: {
        '& .MuiInputBase-root': {
            color: '#000',
            fontWeight: 'bold',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            lineHeight: 1.25,
            backgroundColor: '#FFF',
            borderRadius: '10px',
            '&:hover fieldset': {
                borderColor: "#c8c8c8",
            },
            '&.Mui-focused fieldset': {
                border: "1px solid #c8c8c8"
            },
            "& svg": {
                color: "#969696"
            },
        }
    },
    forwardIcon: {
        backgroundColor: '#d0212a',
        color: '#FFF !important',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        fontSize: '12px',
        marginLeft: '20px'
    },
    toolbarBoxWrapper: {

    },
    searchInfoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '1024px',
        borderBottom: '1px solid #000',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& p': {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            color: '#d0212a',
            textTransform: 'uppercase',
            margin:'20px 0'

        }
    }
})

export default Styles;