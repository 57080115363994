import React from 'react';
import PrivateRoute from './privateRoute';
import { Route, Switch, Redirect } from 'react-router-dom';

/**
 * PRIVATE ROUTE VIEWS
 */
// import AboutView from '../screens/about';
import VideosView from '../screens/videos';
import PlayerView from '../screens/player';

/**
 * PUBLIC ROUTE VIEWS
 */
import SignIn from '../screens/auth/SignIn';
import RecoverPassword from '../screens/auth/RecoverPassword';
import NewPasswordRequired from '../screens/auth/NewPasswordRequired';
import RecoverPasswordConfirm from '../screens/auth/RecoverPasswordConfirm';
import Unauthorized from '../screens/auth/Unauthorized';


const checkUnauthorized = () => {
    if (window.location.pathname === "/auth/unauthorized") {
        return "/auth/unauthorized";
    }
    return "/auth/login"
}

export const PublicRoutes = (props) => {
    return (
        <Switch>
            <Route path="/auth/login" component={SignIn} exact />
            <Route path="/auth/unauthorized" component={Unauthorized} exact />
            <Route path="/auth/recover-password" component={RecoverPassword} exact />
            <Route path="/auth/recover-password/confirm" component={RecoverPasswordConfirm} exact />
            <Route path="/auth/new-password-required" component={NewPasswordRequired} exact />
            <Redirect from="/" to={checkUnauthorized()} />
        </Switch>
    )
}

export const PrivateRoutes = () => {
    return (
        <Switch>
            {/* <PrivateRoute path="/about" redirect="/" component={AboutView} exact /> */}
            <PrivateRoute path="/videos" redirect="/" component={VideosView} exact />
            <PrivateRoute path="/videos/:videoId" redirect="/" component={PlayerView} exact />
            <Redirect from="/" to="/videos" />
        </Switch>
    )
}