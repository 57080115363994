import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/auth/auth-context';

const PrivateRoute = ({ redirect: redirectPath, component: Component, ...rest }) => {

    const { isAuthenticated, isLoading } = useContext(AuthContext)

    return (
        <Route {...rest} render={props => (
            !isLoading ? (isAuthenticated ? (<Component {...props} />) : (<Redirect to={{ pathname: redirectPath }} />)) : <div></div>
        )}
        />
    );
}

export default PrivateRoute;